import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import ServiceClosed from './pages/ServiceClosed/ServiceClosed';

declare global {
  interface Window {
    adsbygoogle: any[] | any,
    ga: any,
    gtag: any
  }
}

ReactDOM.render(
  <React.StrictMode>
    <ServiceClosed />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
