import React from 'react'
import GlobalStyles from '../../app/GlobalStyles'
import styled from 'styled-components';
import { Typography } from 'antd';
import { generate } from '@ant-design/colors';
import BuyDomainForm from './BuyDomainForm';

interface Props {
    background: string
}

const AppHeader = styled.div<Props>`
	background-color: ${props => props.background};
	font-size: 1.4rem;
`;

const AppHeaderContentContainer = styled.div`
	display: flex;
	align-items: center;
	color: white;
	max-width: 1800px;
	min-height: 3em;

	margin-left: 3%;

	@media screen and (min-width: 1920px) {
		margin-right: 10%;
		margin-left: 10%;
	}
`;

const product = process.env.REACT_APP_PRODUCT;

const getProductSpecifics = (): any => {
    const colors: string[] = (product === "XBOX") ? generate('#107c10') : generate('#0070D1');
    const siteTitle: string = (product === "XBOX") ? "Tietoa Xbox Series S/X -konsoleista Suomessa" : "Tietoa PS5:stä kiinnostuneille Suomessa"
    const siteHeader: string = (product === "XBOX") ? "Xbox Series S/X info" : "Playstation 5 info"

    return {
        colors,
        siteTitle,
        siteHeader
    }
}

const MainContentContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    min-height: 400px;
`;

export default function ServiceClosed() {
    return (
        <>
            <GlobalStyles background={"white"} />
            <AppHeader background={getProductSpecifics().colors[6] ?? ""}>
                <AppHeaderContentContainer>
                    <div>
                        <Typography.Title level={1} style={{ color: "white", marginBottom: "0" }}>{getProductSpecifics().siteHeader}</Typography.Title>
                    </div>
                </AppHeaderContentContainer>
            </AppHeader>
            <MainContentContainer>
                <p>
                    <span>
                        Palvelun toiminta on päättynyt. Kiitos kaikille palvelun käyttäjille!
                    </span>
                </p>
                <br />
                <br />
                <p>
                    <span>
                        Palvelussa olleet sähköpostiosoitteet on poistettu.
                    </span>
                </p>
                <br />
                <br />
                <p>
                    <span>
                        Alla olevalla lomakkeella voit laittaa vielä palautetta tai kysymyksiä.
                    </span>
                </p>
                <br />
                <BuyDomainForm />
            </MainContentContainer>
        </>
    )
}