import { createGlobalStyle } from 'styled-components';
//import './reset.css';
import 'antd/dist/antd.css';

interface Props {
	background: string
}

const GlobalStyles = createGlobalStyle<Props>` 
	html {
	font-size: 14px;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	min-width: 300px;
	overflow-x: hidden;
	overflow-y: scroll;
	text-rendering: optimizeLegibility;

		&.mobile-menu-open {
		overflow: hidden;
		}
	}

	body {
	/* color: #666; */
	font-family: "Roboto Condensed", sans-serif;
	font-size: 1.4rem;
	background-color: ${props => props.background};
	/* font-weight: 400; */
	line-height: 1.8;
		
	@media screen and (min-width: 600px) {
			font-size: 1.2rem;
		}
	}

	a {
	/* text-decoration: none; */
	color: black;
	}

	div,
	h2,
	p,
	figure {
	margin: 0;
	padding: 0;
	}

	h4,
	h5 {
	font-weight: bold;
	}

	.App {
		margin-right: 3%;
		margin-left: 3%;
		max-width: 1800px;
	}

	@media screen and (min-width: 1920px) {
	.App {
		margin-right: 10%;
		margin-left: 10%;
	}
	}	
`;

export default GlobalStyles;