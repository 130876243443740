import { Button, Form, Typography } from 'antd'
import TextArea from 'antd/lib/input/TextArea';
import { ReactElement, useRef, useState } from 'react'
import ReCAPTCHA from "react-google-recaptcha";
import { CaptchaAndButtonsContainer } from '../CaptchaAndButtonsContainer';

const validateMessages = {
    required: 'Syötä kommentti'
};

export default function BuyDomainForm(): ReactElement {
    const [captchaDone, setCaptchaDone] = useState(false);
    const [captcha, setCaptcha] = useState<string | null>(null);
    const [hasErrors, setHasErrors] = useState(true);
    const [commentSentOk, setCommentSentOk] = useState<boolean | null>(null);
    const [form] = Form.useForm();
    const ref = useRef(null);

    const sendEmail = (formValues: any) => {
        const realCall = async () => {
            try {
                const res = await fetch(`${process.env.REACT_APP_API_URL}/api/AddComment`, {
                    body: JSON.stringify({ captcha, comment: formValues.comment }),
                    method: "POST"
                });

                if (res.ok) {
                    setCommentSentOk(true);

                    form.resetFields();
                }
                else {
                    setCommentSentOk(false);

                    try {
                        (ref.current as any).reset();
                        setCaptchaDone(false);
                    } catch (error) {
                        console.log("Failed to reset captcha:" + error);
                    }
                }
            } catch (error) {
                setCommentSentOk(false);

                try {
                    (ref.current as any).reset();
                    setCaptchaDone(false);
                } catch (error) {
                    console.log("Failed to reset captcha:" + error);
                }
            }
        };

        realCall();
    }

    const handleCaptchaChange = (value: string | null) => {
        value === null ? setCaptchaDone(false) : setCaptchaDone(true);

        setCaptcha(value);
        setCommentSentOk(null);
    };

    const handleFieldsChange = (state: any) => {
        if (state[0].errors.length > 0) {
            setHasErrors(true);
        }
        else {
            setHasErrors(false);
        }

        setCommentSentOk(null);
    };

    return (
        <div style={{ "maxWidth": "600px" }}>
            {commentSentOk === false && <div>
                <Typography.Title type="warning" level={5}>Tapahtui virhe. Ole hyvä ja koita uudelleen.</Typography.Title>
            </div>}

            {commentSentOk === true && <div>
                <Typography.Title type="success" level={5}>Kiitos palautteesta!</Typography.Title>
            </div>}

            <Form form={form} validateMessages={validateMessages} onFinish={sendEmail} onFieldsChange={handleFieldsChange} >
                <Form.Item
                    name={['comment']}
                    label=""
                    rules={[
                        {
                            required: true
                        },
                    ]}
                >
                    <TextArea size={"large"} maxLength={3000} />
                </Form.Item>

                <CaptchaAndButtonsContainer>
                    <ReCAPTCHA
                        ref={ref}
                        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY ?? "wont-work"}
                        onChange={handleCaptchaChange}
                    />
                    <Button type="primary" htmlType="submit" disabled={!captchaDone || hasErrors}>Lähetä</Button>
                </CaptchaAndButtonsContainer>
            </Form>
        </div>
    )
}
